<template>
  <div class="auth-wrapper">
    <register />
  </div>
</template>

<script>
import Register from "@/components/auth/boxRegister.vue";
export default {
  name: "auth-page",
  components: {
    Register,
  },
};
</script>

<style lang="scss" scoped>
.auth {
  &-wrapper {
    background-color: $green-color;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      background-color: white;
      z-index: -1;
    }

  }
}
</style>